import React from "react";
import catpopCss from "./addNewCategory.module.css";
import JoditEditor from "jodit-react";
import { UpdateCategoryData } from "../../../../api/CategoryApi";
import toast from "react-hot-toast";
import { UpdateBlogCategory } from "../../../../api/BlogApi";
export default function CategorySEOPop({ popUp, setPopUp }) {
  async function onUpdateSeoData(e) {
    e.preventDefault();
    const meta_title = e.target.meta_title.value;
    const meta_description = e.target.meta_description.value;
    const meta_keywords = e.target.meta_keywords.value;
    try {
      await UpdateBlogCategory(popUp.blog_category_id, {
        meta_title,
        meta_description,
        meta_keywords,
      });
      toast.success("SEO Data Updated");
      setPopUp();
      document.body.style.overflow = "auto";
    } catch (error) {
      toast.error(error.message);
    }
  }
  return (
    <div className="bg-black bg-opacity-50 fixed inset-0 z-[101] flex items-center justify-center">
      <div className="bg-white w-1/2 rounded-lg flex flex-col gap-3 overflow-auto">
        <p className="text-2xl bg-blue-500 text-white p-2 text-center font-bold">
          Update SEO Data for -{popUp.category_name}
        </p>
        <form className="flex flex-col gap-3 p-4" onSubmit={onUpdateSeoData}>
          <div className="flex gap-4">
            <label className="flex-1">Meta Title</label>
            <input
              type="text"
              name="meta_title"
              required
              defaultValue={popUp.meta_title}
              maxLength={199}
              className="flex-[3]"
            />
          </div>
          <div className="flex gap-4">
            <label className="flex-1">Meta Description</label>
            <input
              type="text"
              name="meta_description"
              required
              defaultValue={popUp.meta_description}
              maxLength={199}
              className="flex-[3]"
            />
          </div>
          <div className="flex gap-4">
            <label className="flex-1">Meta Keywords</label>
            <input
              type="text"
              name="meta_keywords"
              defaultValue={popUp.meta_keywords}
              maxLength={199}
              className="flex-[3]"
            />
          </div>
          <div className="flex gap-4 items-center justify-end">
            <button className="px-4 py-2 bg-green-500 text-white">
              Update Category
            </button>
            <button
              type="button"
              onClick={() => {
                document.body.style.overflow = "auto";
                setPopUp();
              }}
              className="px-4 py-2 bg-red-500 text-white"
            >
              close
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
