import React, { useState } from "react";
import toast from "react-hot-toast";
import { API_LINK } from "../../../../api/APILink";
import { CheckRatio } from "../../../../api/GlobalFunctions";
import modelsCss from "./blogimages.module.css";
import { UploadBodyImage } from "../../../../api/BlogApi";
export default function AddBlogImagesPop({ setPopUp }) {
  const [fakeUrl, setFakeUrl] = useState([]);
  function onImageChange(e) {
    const images = e.target.files;
    const urls = Array.from(images).map((item) => {
      return genarateFakeUrl(item);
    });
    setFakeUrl(urls);
  }
  async function onImageUoload(e) {
    e.preventDefault();
    const image = e.target.product_image.files[0];
    const alter_text = e.target.alter_text.value;
    try {
      await UploadBodyImage(image, alter_text);
      toast.success("Image Upload Success");
      setPopUp();
      document.body.style.overflow = "auto";
    } catch (error) {
      toast.success(error.message);
    }
  }
  function genarateFakeUrl(image) {
    let myurl = URL.createObjectURL(image);
    return myurl;
  }
  return (
    <div className="bg-black bg-opacity-50 fixed inset-0 z-[101] flex items-center justify-center">
      <div id="image_show"></div>
      <div className="bg-white w-1/2 rounded-lg flex flex-col gap-3">
        <div className="bg-blue-500 text-white p-2 relative">
          <h3>Upload Blog Images</h3>
          <button
            onClick={() => {
              setPopUp();
              document.body.style.overflow = "auto";
            }}
            className="absolute right-4 top-1 bg-red-500 py-1 px-2"
          >
            Close
          </button>
        </div>
        <form
          className="flex gap-3 justify-between px-4"
          onSubmit={onImageUoload}
        >
          <input
            name="product_image"
            type="file"
            required
            onChange={onImageChange}
            multiple={false}
            accept="image/webp"
          />
          <input
            name="alter_text"
            type="text"
            required
            placeholder="alter text"
            accept="image/webp"
          />
          <button className="px-4 py-2 bg-green-500 text-white">Upload</button>
        </form>
        <div className={modelsCss.img_holder}>
          {fakeUrl.map((item, index) => {
            return <img key={index} src={item} alt={"no data found"} />;
          })}
        </div>
      </div>
    </div>
  );
}
