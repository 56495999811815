import Axios from "axios";
import { API_LINK } from "./APILink";

const configdata = {
  headers: {
    "Content-Type": "application/json",
  },
};

const configfile = {
  headers: {
    "Content-Type": "multipart/form-data",
  },
};

export async function AddBlogCategory(passedData) {
  let result = await Axios.post(
    `${API_LINK}blog/add-blog-category`,
    passedData,
    configdata
  );
  return result.data;
}
export async function GetBlogCategory() {
  let result = await Axios.get(`${API_LINK}blog/get-blog-category`);
  return result.data;
}
export async function UpdateBlogCategory(blog_category_id, passedData) {
  let result = await Axios.put(
    `${API_LINK}blog/update-blog-category/${blog_category_id}`,
    passedData,
    configdata
  );
  return result.data;
}

// blog posts
export async function AddBlogPosts(passedData) {
  let result = await Axios.post(
    `${API_LINK}blog/add-blog-posts`,
    passedData,
    configdata
  );
  return result.data;
}
export async function UpdateBlogPosts(blog_post_id, passedData) {
  let result = await Axios.put(
    `${API_LINK}blog/update-blog-posts/${blog_post_id}`,
    passedData,
    configdata
  );
  return result.data;
}

export async function GetBlogPosts() {
  let result = await Axios.get(`${API_LINK}blog/get-blog-posts`);
  return result.data;
}
export async function GetBlogPostByQuery(query) {
  let result = await Axios.get(
    `${API_LINK}blog/get-blog-post-by-query?${query}`
  );
  return result.data;
}

// image uploader
export async function UploadBlogImage(imagedata, blog_post_id, image_name) {
  const formData = new FormData();
  formData.append("myfile", imagedata);
  let resultImage = await Axios.post(
    `${API_LINK}blog/upload-blog-images`,
    formData,
    configfile
  );
  await UpdateBlogPosts(blog_post_id, { blog_image_name: resultImage.data });
  await DeleteBlogImage(image_name);
  return resultImage;
}
export async function DeleteBlogImage(image_name) {
  let resultImage = await Axios.post(
    `${API_LINK}blog/delete-blog-images`,
    { image_name },
    configdata
  );
  return resultImage;
}

export async function GetBodyImages() {
  let results = await Axios.post(`${API_LINK}blog/get-body-images`);
  return results.data;
}
export async function UploadBodyImage(imagedata, alter_text) {
  const formData = new FormData();
  formData.append("myfile", imagedata);
  let resultImage = await Axios.post(
    `${API_LINK}blog/upload-body-images`,
    formData,
    configfile
  );
  let resultAdd = await Axios.post(
    `${API_LINK}blog/add-body-image`,
    { image_name: resultImage.data, alter_text },
    configdata
  );
  return resultAdd;
}

// blog related Products from here

export async function AddBlogRelatedProduct(passedData) {
  const res = await Axios.post(
    `${API_LINK}blog/add-blog-related-product`,
    passedData,
    configdata
  );
  return res.data;
}

export async function GetBlogRelatedProduct(query) {
  const res = await Axios.get(
    `${API_LINK}blog/get-blog-related-product?${query}`
  );
  return res.data;
}
