import React, { useEffect, useState } from "react";
import { FaRegTimesCircle } from "react-icons/fa";
import { FaRegCircleCheck } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Select from "react-select";
import { selectBrandList } from "../../ReduxFiles/Slices/BrandListSlice";
import { selectCategoryList } from "../../ReduxFiles/Slices/CategorySlice";
import {
  fetchProducts,
  selectProductList,
  selectProductStatus,
} from "../../ReduxFiles/Slices/ProductSlice";
import { UpdateProductList } from "../../api/ProductListApi";
import ToggleSwitch from "../smallThings/ToggleSwitch";
import SeoPop from "./productlistmodels/SeoPop";

import AgePop from "./productlistmodels/AgePop";
import AvaillableStock from "./productlistmodels/AvaillableStock";
import CommonAlertFile from "./productlistmodels/CommonAlertFile";

async function getAllData(
  setTableData,
  setExistedCatagory,
  allProducts,
  categoryList
) {
  let tempArr = [];
  allProducts?.forEach((element) => {
    if (element.is_active) {
      tempArr.push(element);
    }
  });
  // console.log(data)
  let sortArr = tempArr.sort((a, b) => (a.item_code > b.item_code ? 1 : -1));
  setTableData(sortArr);
  getExistedCat(allProducts, setExistedCatagory, categoryList);
}
function getExistedCat(arrayData, setExistedCatagory, categoryList) {
  let catArr = [];
  let myArr = [];
  arrayData.forEach((element) => {
    if (catArr.includes(element.selected_catagory_id)) {
    } else {
      catArr.push(element.selected_catagory_id);
      for (let index = 0; index < categoryList?.length; index++) {
        const melement = categoryList[index];
        if (element.selected_catagory_id === melement.id) {
          myArr.push(melement);
        }
      }
    }
  });
  setExistedCatagory(myArr);
}

export default function ProductList() {
  const dispatch = useDispatch();
  const allProducts = useSelector(selectProductList);
  const productStatus = useSelector(selectProductStatus);
  const categoryList = useSelector(selectCategoryList);
  const brandList = useSelector(selectBrandList);
  const [tableData, setTableData] = useState([]);
  const [stockAmmountState, setStockAmmountState] = useState();
  const [existedCatagory, setExistedCatagory] = useState();

  function StockEntry(e, item) {
    setStockAmmountState(item);
  }

  useEffect(() => {
    if (productStatus === "success") {
      getAllData(setTableData, setExistedCatagory, allProducts, categoryList);
    }
  }, [productStatus, allProducts, categoryList]);

  useEffect(() => {
    if (productStatus === "idle") {
      dispatch(fetchProducts());
    }
  }, [productStatus, dispatch]);

  const [seoData, setSeoData] = useState();
  async function onSeoClick(item) {
    setSeoData(item);
    document.body.style.overflow = "hidden";
  }

  const [ageItem, setAgeItem] = useState();

  function onAddAgeClick(item) {
    setAgeItem(item);
  }

  async function onDeliveryClick(item) {
    let mfree_delivery = 0;
    if (!item.free_delivery) {
      mfree_delivery = 1;
    }
    await UpdateProductList(item.id, { free_delivery: mfree_delivery });
    let tempArr = [];
    tableData?.forEach((element) => {
      if (element.id === item.id) {
        element = {
          ...element,
          free_delivery: mfree_delivery,
        };
      }
      tempArr.push(element);
    });
    setTableData(tempArr);
  }

  async function onActiveClick(item) {
    let mis_active = 0;
    if (!item.is_active) {
      mis_active = 1;
    }
    await UpdateProductList(item.id, { is_active: mis_active });
    let tempArr = [];
    tableData?.forEach((element) => {
      if (element.id === item.id) {
        element = {
          ...element,
          is_active: mis_active,
        };
      }
      tempArr.push(element);
    });
    setTableData(tempArr);
  }

  const [searchData, setSearchData] = useState();

  function onBrandChange(item) {
    if (item) {
      let value = item.id;
      let newArr = [];
      if (value) {
        allProducts?.forEach((item) => {
          if (item.selected_brand_id === parseInt(value)) {
            newArr.push(item);
          }
        });
      } else {
        newArr = allProducts;
      }
      setSearchData({ ...searchData, brand: value, catagory: "" });
      setTableData(newArr);
      getExistedCat(newArr, setExistedCatagory, categoryList);
    } else {
      setTableData(allProducts);
      getExistedCat(allProducts, setExistedCatagory, categoryList);
    }
  }
  function onCatagoryChange(item) {
    if (item) {
      let value = item.id;
      let newArr = [];
      if (value) {
        allProducts?.forEach((item) => {
          if (item.selected_catagory_id === parseInt(value)) {
            newArr.push(item);
          }
        });
      } else {
        newArr = allProducts;
      }
      setSearchData({ ...searchData, catagory: value });
      setTableData(newArr);
    } else {
      setTableData(allProducts);
    }
  }
  function onItemClick(item) {
    if (item) {
      let myArr = [];
      myArr.push(item);
      setTableData(myArr);
    } else {
      setTableData(allProducts);
    }
  }
  function onCheckChange(e) {
    const isChecked = e.target.checked;
    let mcheck = isChecked ? 1 : 0;
    let newArr = [];
    allProducts?.forEach((item) => {
      if (item.is_active === mcheck) {
        newArr.push(item);
      }
    });
    setTableData(newArr);
  }

  const [alertState, setAlertState] = useState();
  function onYesNoClick(item, identity) {
    setAlertState({ item, identity });
    document.body.style.overflow = "hidden";
  }
  return (
    <div className="flex flex-col gap-3">
      {seoData && <SeoPop popData={seoData} setPopData={setSeoData} />}
      {ageItem ? <AgePop popUp={ageItem} setPopUp={setAgeItem} /> : ""}
      {alertState && (
        <CommonAlertFile popUp={alertState} setPopUp={setAlertState} />
      )}
      {stockAmmountState && (
        <AvaillableStock
          popUp={stockAmmountState}
          setPopUp={setStockAmmountState}
        />
      )}

      <div className="grid grid-cols-4 gap-4">
        <p className="bg-white rounded-md shadow-md p-4 text-center text-2xl text-teal-600 flex items-center gap-2 justify-center">
          <strong>{allProducts?.length}</strong> Items
        </p>
        <p className="bg-white rounded-md shadow-md p-4 text-center text-2xl text-teal-600 flex items-center gap-2 justify-center">
          <strong>{categoryList?.length}</strong> Catagory
        </p>
        <p className="bg-white rounded-md shadow-md p-4 text-center text-2xl text-teal-600 flex items-center gap-2 justify-center">
          <strong>{brandList?.length}</strong> Brands
        </p>
        <Link
          className="bg-white rounded-md shadow-md p-4 text-center text-2xl text-teal-600 flex items-center gap-2 justify-center"
          to={"/products/add-new-product"}
        >
          Add New Product
        </Link>
      </div>

      <div className="flex gap-4 items-center bg-white rounded-md shadow-md p-4 sticky top-[47px] z-10">
        <p className="text-2xl font-bold text-teal-600">Filter Product List</p>

        <div className="grid grid-cols-3 gap-3 flex-1">
          <Select
            options={brandList}
            getOptionLabel={(option) => `${option.BrandName}`}
            getOptionValue={(option) => `${option.id}`}
            placeholder={"select brand"}
            isClearable
            onChange={onBrandChange}
          />

          <Select
            options={existedCatagory}
            getOptionLabel={(option) => `${option.catName}`}
            getOptionValue={(option) => `${option.id}`}
            placeholder={"select category"}
            isClearable
            onChange={onCatagoryChange}
          />

          <Select
            options={allProducts}
            getOptionLabel={(option) =>
              `${option.en_sort_title} - (${option.Selling_price}/-)`
            }
            getOptionValue={(option) => `${option.id}`}
            placeholder={"select product"}
            isClearable
            onChange={onItemClick}
          />
        </div>
      </div>
      <div className="custom-table">
        <table>
          <thead>
            <tr>
              <th>Sl</th>
              <th>Item Name</th>
              <th>
                <span>
                  Active{" "}
                  <input
                    type="checkbox"
                    defaultChecked={true}
                    onChange={onCheckChange}
                  />
                </span>
              </th>
              <th>Category</th>
              <th>Brand</th>
              <th>Featured</th>
              <th>Stock Out</th>
              <th>Avi Stock</th>
              <th>Free Delivery</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {tableData.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td className="text-left">
                    {item.en_sort_title}-({item.Selling_price}/-) <br />
                    {item.hasAge ? (
                      <button
                        onClick={() => {
                          onAddAgeClick(item);
                          document.body.style.overflow = "hidden";
                        }}
                        className="bg-orange-600 text-white px-2 py-1"
                      >
                        add age value
                      </button>
                    ) : (
                      ""
                    )}
                  </td>
                  <td>
                    <div className="flex items-center justify-center">
                      <ToggleSwitch
                        toggleOnOff={item.is_active}
                        toggleClick={() => {
                          onActiveClick(item);
                        }}
                      />
                    </div>
                  </td>
                  <td>{item.catName}</td>
                  <td>{item.BrandName}</td>
                  <td>
                    <div
                      className={`flex gap-2 items-center justify-center ${
                        item.featured
                          ? "text-green-600 font-bold"
                          : "text-gray-500"
                      }`}
                    >
                      <FaRegTimesCircle
                        onClick={() => {
                          onYesNoClick(item, "notfeatured");
                        }}
                      />
                      Featured
                      <FaRegCircleCheck
                        onClick={() => {
                          onYesNoClick(item, "featured");
                        }}
                      />
                    </div>
                  </td>
                  <td>
                    <div
                      className={`flex gap-2 items-center justify-center ${
                        item.featured
                          ? "text-red-600 font-bold"
                          : "text-green-600"
                      }`}
                    >
                      <FaRegTimesCircle
                        onClick={() => {
                          onYesNoClick(item, "stockout");
                        }}
                      />
                      Applicable
                      <FaRegCircleCheck
                        onClick={() => {
                          onYesNoClick(item, "instock");
                        }}
                      />
                    </div>
                  </td>

                  <td>
                    {
                      <p
                        onClick={(e) => {
                          StockEntry(e, item);
                          document.body.style.overflow = "hidden";
                        }}
                        className={`font-bold ${
                          item.hasVariance ? "text-green-700" : "text-blue-700"
                        }`}
                      >
                        {item?.virtualStock ? item.virtualStock : 0}
                      </p>
                    }
                  </td>

                  <td>
                    <div className="flex items-center justify-center">
                      <ToggleSwitch
                        toggleOnOff={item.free_delivery}
                        toggleClick={() => {
                          onDeliveryClick(item);
                        }}
                      />
                    </div>
                  </td>
                  <td>
                    <div className="flex gap-2 items-center justify-center">
                      <button className="bg-teal-600 text-white px-3 py-1">
                        <Link className="text-white" to={`${item.id}`}>
                          Edit
                        </Link>
                      </button>
                      <button
                        className="bg-orange-600 text-white px-3 py-1"
                        onClick={() => {
                          onSeoClick(item);
                        }}
                      >
                        SEO
                      </button>
                    </div>
                  </td>
                </tr>
              );
            })}

            {/* {CatItem} */}
          </tbody>
        </table>
      </div>
    </div>
  );
}
