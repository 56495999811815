import toast from "react-hot-toast";
import Select from "react-select";
import { AddBlogCategory } from "../../../../api/BlogApi";
import addNewCategoryCss from "./addNewCategory.module.css";

export default function AddNewCategory({ setUsePoup }) {
  async function onAddCategory(e) {
    e.preventDefault();
    const category_name = e.target.category_name.value;
    const category_slug = e.target.category_slug.value;
    const parent_category = e.target.parent_category.value;
    try {
      await AddBlogCategory({ category_name, category_slug, parent_category });
      toast.success("Category Added Successfully!");
      setUsePoup(false);
    } catch (error) {
      toast.error(error.response.data);
    }
  }

  async function Close() {
    setUsePoup(false);
  }
  return (
    <div className="bg-black bg-opacity-50 fixed inset-0 z-[101] flex items-center justify-center">
      <div className="bg-white w-1/2 rounded-lg flex flex-col gap-3">
        <p className="text-2xl bg-blue-500 text-white p-2 text-center font-bold">
          Add New Blog Category
        </p>
        <form className="flex flex-col gap-3 p-4" onSubmit={onAddCategory}>
          <div className="flex gap-4">
            <label className="flex-1">Category Name</label>
            <input
              type="text"
              name="category_name"
              required
              className="flex-[3]"
            />
          </div>
          <div className="flex gap-4">
            <label className="flex-1">Category Slug</label>
            <input
              type="text"
              name="category_slug"
              required
              className="flex-[3]"
            />
          </div>
          <div className="flex gap-4">
            <label className="flex-1">Parent Category</label>
            <Select className="flex-[3]" name="parent_category" />
          </div>
          <div className="flex gap-4 items-center justify-end">
            <button className="px-4 py-2 bg-green-500 text-white">
              Add Category
            </button>
            <button
              type="button"
              onClick={() => Close()}
              className="px-4 py-2 bg-red-500 text-white"
            >
              close
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
