import JoditEditor from "jodit-react";
import React from "react";
import { UpdateBlogPosts } from "../../../../api/BlogApi";
import { toast } from "react-toastify";
export default function BlogContent({ popUp, setPopUp }) {
  const config = {
    uploader: {
      insertImageAsBase64URI: true,
    },
    placeholder: "Type Your Content Here...",
  };

  async function onUpdateDescription(e) {
    e.preventDefault();

    try {
      const blog_content_en = e.target.blog_content_en.value;
      const blog_content_bn = e.target.blog_content_bn.value;
      await UpdateBlogPosts(popUp.blog_post_id, {
        blog_content_en,
        blog_content_bn,
      });
      toast.success("Content Updated!");
      setPopUp();
      document.body.style.overflow = "auto";
    } catch (error) {
      // toast.error(error.response.data);
      toast.error(error.message);
    }
  }
  return (
    <div className="bg-black bg-opacity-50 fixed inset-0 z-[101] flex items-center justify-center">
      {/* <div id="image_show"></div> */}
      <div className="bg-white w-3/4 rounded-lg flex flex-col gap-3 h-[95vh] overflow-auto">
        <div className="bg-blue-500 text-white p-2 sticky top-0 z-10">
          <h3>Update Blog Content</h3>
          <p>Blog Title : {popUp.blog_title_en}</p>
          <button
            onClick={() => {
              setPopUp();
            }}
            className="absolute right-4 top-3 bg-red-500 p-2"
          >
            Close
          </button>
        </div>
        <form className="px-4" onSubmit={onUpdateDescription}>
          <div className="flex flex-col gap-3">
            <p className="text-center text-2xl">ব্লগ কন্টেন্ট (বাংলা)</p>
            <JoditEditor
              value={popUp.blog_content_bn || ""}
              config={config}
              tabIndex={1}
              name="blog_content_bn"
            />
          </div>

          <div className="flex flex-col gap-3">
            <p className="text-center text-2xl">Blog Content(English)</p>
            <JoditEditor
              value={popUp.blog_content_en || ""}
              config={config}
              tabIndex={1}
              name="blog_content_en"
            />
          </div>

          <div className="flex items-center justify-center py-4">
            <button className="bg-green-600 text-white py-2 px-4">
              Update Content
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
