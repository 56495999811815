import React, { useState } from "react";
import imageCss from "./imagepop.module.css";
import toast from "react-hot-toast";
import {
  UpdateCategoryData,
  UploadCategoryImage,
} from "../../../../api/CategoryApi";
import { API_LINK } from "../../../../api/APILink";

export default function ImagePop({ popUp, setPopUp }) {
  const [fakeUrl, setFakeUrl] = useState();
  async function onAddImage(e) {
    e.preventDefault();
    try {
      const category_image = e.target.category_image.files[0];
      // const response = await UploadCategoryImage(
      //   category_image,
      //   popUp.cat_image_name
      // );
      // if (response) {
      //   await UpdateCategoryData(popUp.category_id, {
      //     cat_image_name: response,
      //   });
      // }
      setPopUp();
      document.body.style.overflow = "auto";
      toast.success("Category Image Updated!");
    } catch (error) {
      toast.error(error.message);
    }
  }

  function onImageChange(e) {
    const categoryImage = e.target.files[0];
    var img = new Image();
    img.src = genarateFakeUrl(categoryImage);
    img.onload = function () {
      const height = this.height;
      const width = this.width;
      let ratio = calculateRatio(width, height);

      if (ratio === "1:1") {
        toast.success("image size is ok");
        setFakeUrl(genarateFakeUrl(categoryImage));
      } else {
        e.target.value = null;
        setFakeUrl();
        toast.error(
          `image size is ${width} & ${height} AND Ration is ${ratio}. Expected Ratio is 1:1`
        );
      }
    };
  }

  function calculateRatio(num_1, num_2) {
    for (let num = num_2; num > 1; num--) {
      if (num_1 % num == 0 && num_2 % num == 0) {
        num_1 = num_1 / num;
        num_2 = num_2 / num;
      }
    }
    let ratio = num_1 + ":" + num_2;
    return ratio;
  }

  function genarateFakeUrl(image) {
    let myurl = URL.createObjectURL(image);
    return myurl;
  }

  return (
    <div className={imageCss.wrapper}>
      <div className={imageCss.holder}>
        <div className={imageCss.head}>
          <h3>Add Category Image</h3>
        </div>
        <form className={imageCss.input_section} onSubmit={onAddImage}>
          <div>
            <input
              type="file"
              multiple={false}
              name="category_image"
              onChange={onImageChange}
              required
            />
            <button>Add Image</button>
          </div>
          <div className={imageCss.images}>
            <img
              src={`${API_LINK}blogimages/categoryimages/${popUp.category_image_name}`}
              alt="previous image"
            />
            {fakeUrl && <img src={fakeUrl} alt="corrent " />}
          </div>
        </form>
        <button
          onClick={() => {
            setPopUp();
            document.body.style.overflow = "auto";
          }}
        >
          Close
        </button>
      </div>
    </div>
  );
}
