import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import Select from "react-select";
import { GetBlogCategory, UpdateBlogPosts } from "../../../../api/BlogApi";
async function getData(setData) {
  const data = await GetBlogCategory();
  setData(data);
}
export default function EditBlog({ popUp, setPopUp }) {
  const [addData, setAddData] = useState({});
  useEffect(() => {
    getData(setAddData);
  }, []);
  async function onUpdateBlog(e) {
    e.preventDefault();
    const blog_title_en = e.target.blog_title_en.value;
    const blog_title_bn = e.target.blog_title_bn.value;
    const blog_sub_title_en = e.target.blog_sub_title_en.value;
    const blog_sub_title_bn = e.target.blog_sub_title_bn.value;
    const blog_slug = e.target.blog_slug.value;
    const meta_title = e.target.meta_title.value;
    const meta_description = e.target.meta_description.value;
    const meta_keywords = e.target.meta_keywords.value;
    const blog_category_id = e.target.blog_category_id.value;
    const finalData = {
      blog_title_en,
      blog_title_bn,
      blog_sub_title_en,
      blog_sub_title_bn,
      blog_slug,
      meta_title,
      meta_description,
      meta_keywords,
      blog_category_id,
    };

    try {
      await UpdateBlogPosts(popUp.blog_post_id, finalData);
      toast.success("Blog Update Success");
      setPopUp();
    } catch (error) {
      toast.error(error.message);
    }
  }

  return (
    <div className="bg-black bg-opacity-50 fixed inset-0 z-[101] flex items-center justify-center">
      <div className="bg-white w-2/3 rounded-lg flex flex-col gap-3">
        <div className="bg-blue-500 text-white p-2 relative">
          <h3>Update Blog Data</h3>
          <p>Product Name : {popUp.blog_title_en}</p>
          <button
            onClick={() => {
              setPopUp();
            }}
            className="absolute right-4 top-3 bg-red-500 p-2"
          >
            Close
          </button>
        </div>
        <form className="grid grid-cols-2 gap-4 p-4" onSubmit={onUpdateBlog}>
          <div className="flex gap-2 items-center">
            <label className="flex-1">Blog Title (en)*</label>
            <input
              type="text"
              name="blog_title_en"
              defaultValue={popUp.blog_title_en}
              required
              className="flex-[2]"
            />
          </div>
          <div className="flex gap-2 items-center">
            <label className="flex-1">Blog Title (bn)*</label>
            <input
              type="text"
              name="blog_title_bn"
              defaultValue={popUp.blog_title_bn}
              required
              className="flex-[2]"
            />
          </div>
          <div className="flex gap-2 items-center">
            <label className="flex-1">Blog Sub Title (en)*</label>
            <input
              type="text"
              name="blog_sub_title_en"
              defaultValue={popUp.blog_sub_title_en}
              required
              className="flex-[2]"
            />
          </div>
          <div className="flex gap-2 items-center">
            <label className="flex-1">Blog Sub Title (bn)*</label>
            <input
              type="text"
              name="blog_sub_title_bn"
              defaultValue={popUp.blog_sub_title_bn}
              required
              className="flex-[2]"
            />
          </div>
          <div className="flex gap-2 items-center">
            <label className="flex-1">Blog Slug*</label>
            <input
              type="text"
              name="blog_slug"
              defaultValue={popUp.blog_slug}
              required
              className="flex-[2]"
            />
          </div>
          <div className="flex gap-2 items-center">
            <label className="flex-1">Meta Title *</label>
            <input
              type="text"
              name="meta_title"
              defaultValue={popUp.meta_title}
              required
              className="flex-[2]"
            />
          </div>
          <div className="flex gap-2 items-center">
            <label className="flex-1">Meta Description *</label>
            <input
              type="text"
              name="meta_description"
              defaultValue={popUp.meta_description}
              required
              className="flex-[2]"
            />
          </div>
          <div className="flex gap-2 items-center">
            <label className="flex-1">Meta Keywords *</label>
            <input
              type="text"
              name="meta_keywords"
              defaultValue={popUp.meta_keywords}
              required
              className="flex-[2]"
            />
          </div>
          <div className="flex gap-2 items-center">
            <label className="flex-1">Blog Category*</label>
            <Select
              options={addData || []}
              getOptionLabel={(option) => `${option.category_name}`}
              getOptionValue={(option) => `${option.blog_category_id}`}
              name="blog_category_id"
              // required
              isClearable
              defaultValue={{
                category_name: popUp.category_name,
                blog_category_id: popUp.blog_category_id,
              }}
              className="flex-[2]"
            />
          </div>
          <div className="flex gap-2 col-span-2 items-center justify-center">
            <button className="px-4 py-2 bg-green-500 text-white">
              Update Blog Data
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
