import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import Select from "react-select";
import { AddBlogPosts, GetBlogCategory } from "../../../api/BlogApi";

async function getData(setData) {
  const data = await GetBlogCategory();
  setData(data);
}
export default function AddNewBlog() {
  const [addData, setAddData] = useState({});
  useEffect(() => {
    getData(setAddData);
  }, []);
  async function onAddNewProduct(e) {
    e.preventDefault();
    const blog_title_en = e.target.blog_title_en.value;
    const blog_title_bn = e.target.blog_title_bn.value;
    const blog_sub_title_en = e.target.blog_sub_title_en.value;
    const blog_sub_title_bn = e.target.blog_sub_title_bn.value;
    const blog_slug = e.target.blog_slug.value;
    const meta_title = e.target.meta_title.value;
    const meta_description = e.target.meta_description.value;
    const meta_keywords = e.target.meta_keywords.value;
    const blog_category_id = e.target.blog_category_id.value;
    const finalData = {
      blog_title_en,
      blog_title_bn,
      blog_sub_title_en,
      blog_sub_title_bn,
      blog_slug,
      meta_title,
      meta_description,
      meta_keywords,
      blog_category_id,
    };

    try {
      await AddBlogPosts(finalData);
      e.target.reset();
      toast.success("Blog Added Successfully!");
    } catch (error) {
      toast.error(error.response.data);
    }
  }

  return (
    <div className="flex flex-col gap-4 w-[96%] mx-auto my-6">
      <form className="grid grid-cols-2 gap-4" onSubmit={onAddNewProduct}>
        <div className="flex gap-2 items-center">
          <label className="flex-1">Blog Title (en)*</label>
          <input
            className="flex-[2]"
            type="text"
            name="blog_title_en"
            required
          />
        </div>
        <div className="flex gap-2 items-center">
          <label className="flex-1">Blog Title (bn)*</label>
          <input
            className="flex-[2]"
            type="text"
            name="blog_title_bn"
            required
          />
        </div>
        <div className="flex gap-2 items-center">
          <label className="flex-1">Blog Sub Title (en)*</label>
          <input
            className="flex-[2]"
            type="text"
            name="blog_sub_title_en"
            required
          />
        </div>
        <div className="flex gap-2 items-center">
          <label className="flex-1">Blog Sub Title (bn)*</label>
          <input
            className="flex-[2]"
            type="text"
            name="blog_sub_title_bn"
            required
          />
        </div>
        <div className="flex gap-2 items-center">
          <label className="flex-1">Blog Slug*</label>
          <input className="flex-[2]" type="text" name="blog_slug" required />
        </div>
        <div className="flex gap-2 items-center">
          <label className="flex-1">Meta Title *</label>
          <input className="flex-[2]" type="text" name="meta_title" required />
        </div>
        <div className="flex gap-2 items-center">
          <label className="flex-1">Meta Description *</label>
          <input
            className="flex-[2]"
            type="text"
            name="meta_description"
            required
          />
        </div>
        <div className="flex gap-2 items-center">
          <label className="flex-1">Meta Keywords *</label>
          <input
            className="flex-[2]"
            type="text"
            name="meta_keywords"
            required
          />
        </div>
        <div className="flex gap-2 items-center">
          <label className="flex-1">Blog Category*</label>
          <Select
            options={addData || []}
            getOptionLabel={(option) => `${option.category_name}`}
            getOptionValue={(option) => `${option.blog_category_id}`}
            name="blog_category_id"
            required
            isClearable
            className="flex-[2]"
          />
        </div>
        <div className="flex gap-2 col-span-2 items-center justify-center">
          <button className="px-4 py-2 bg-green-500 text-white">
            Add New Blog
          </button>
        </div>
      </form>
    </div>
  );
}
