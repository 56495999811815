import React from "react";
import { Link } from "react-router-dom";
import styles from "./blogmain.module.css";
export default function BlogMain() {
  const blogMenu = [
    { title: "Blog List", link: "blog-list" },
    { title: "Add New Blog", link: "add-new-blog" },
    { title: "Blog Categories", link: "blog-categories" },
    { title: "Blog Keywords", link: "blog-keywords" },
    { title: "Blog Images", link: "blog-images" },
  ];
  return (
    <div className="flex gap-3 w-[96%] mx-auto py-6">
      <div className=" w-full grid grid-cols-4 gap-4">
        {blogMenu.map((item, index) => {
          return (
            <Link
              to={item.link}
              key={index}
              className="w-full aspect-[2/1] bg-white flex items-center justify-center shadow-xl"
            >
              {item.title}
            </Link>
          );
        })}
      </div>
    </div>
  );
}
