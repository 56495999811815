import { useEffect, useState } from "react";
import { FaEdit } from "react-icons/fa";
import { TiArrowMaximiseOutline } from "react-icons/ti";
import Select from "react-select";
import { GetBlogCategory } from "../../../api/BlogApi";
import AddNewCategory from "./CategoryPop/AddNewCategory";
import CategorySEOPop from "./CategoryPop/CategorySEOPop";
import ImagePop from "./CategoryPop/ImagePop";
import UpdateCategory from "./CategoryPop/UpdateCategory";

async function getCategory(setData) {
  const data = await GetBlogCategory();
  setData(data);
}
export default function CategoryMain() {
  const [tableData, setTableData] = useState([]);
  const [usePopup, setUsePoup] = useState(false);
  const [editItem, setEditItem] = useState();
  const [seoData, setSeoData] = useState();

  async function addNewCategory() {
    setUsePoup(true);
  }
  const [imagePop, setImagePop] = useState();
  useEffect(() => {
    if (!editItem && !seoData && !imagePop && !usePopup) {
      getCategory(setTableData);
    }
  }, [editItem, seoData, imagePop, usePopup]);

  return (
    <div className="flex flex-col gap-4 w-[96%] mx-auto">
      {imagePop && <ImagePop popUp={imagePop} setPopUp={setImagePop} />}
      {usePopup && (
        <AddNewCategory usePopup={usePopup} setUsePoup={setUsePoup} />
      )}
      {editItem && <UpdateCategory popUp={editItem} setPopUp={setEditItem} />}
      {seoData && <CategorySEOPop popUp={seoData} setPopUp={setSeoData} />}

      <div className="flex gap-4 items-center py-4 bg-white">
        <button
          onClick={() => addNewCategory(true)}
          className="bg-green-600 text-white py-2 px-4"
        >
          Add New Category
        </button>
        <Select className="flex-1" name="product_type" />
      </div>
      <div className="custom-table">
        <table>
          <thead>
            <tr>
              <th>SL</th>
              <th>Category Name</th>
              <th>Category Slug</th>
              <th>Parent Category</th>
              <th>Image</th>
              <th>SEO</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {tableData.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.category_name}</td>
                  <td>{item.category_slug}</td>
                  <td>{item.parent_name}</td>
                  <td>
                    <button
                      onClick={() => {
                        setImagePop(item);
                      }}
                    >
                      Add Image
                    </button>
                  </td>
                  <td>
                    <button
                      // className={categoryMainCss.seo}
                      onClick={() => {
                        setEditItem(setSeoData(item));
                        document.body.style.overflow = "hidden";
                      }}
                    >
                      <TiArrowMaximiseOutline />
                    </button>
                  </td>
                  <td>
                    <button
                      // className={categoryMainCss.edit}
                      onClick={() => {
                        setEditItem(item);
                        document.body.style.overflow = "hidden";
                      }}
                    >
                      <FaEdit />
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
