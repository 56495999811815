import { Line } from "rc-progress";
import React, { useEffect, useState } from "react";
import {
  FaChartBar,
  FaChartLine,
  FaStar,
  FaStarHalfAlt,
  FaUsers,
} from "react-icons/fa";
import { HiDotsHorizontal } from "react-icons/hi";
import { IoClose } from "react-icons/io5";
import { MdOutlinePendingActions } from "react-icons/md";
import { Link } from "react-router-dom";
import { GetDashboardData } from "../../api/CombineApi";
import { ServerTimeToFormateTime } from "../../api/GlobalFunctions";
import { GetProductImageLink } from "../../api/ProductListApi";
import { GetIndividualInvoiceWiseReportProductWise } from "../../api/SalesApi";
import MyChart from "../DashboardContent/MyChart";
import InvoiceDetails from "../Setting/ManageUser/MyProfile/reports/models/InvoiceDetails";
import { GetEndDate, StartDateOfMonth } from "../MyFunctions/MyFunctions";
import PieChart from "../DashboardContent/PieChart";
import { OrderMethodObject } from "../global/GlobalConstant";
async function getData(setData) {
  const start_date = new Date(StartDateOfMonth()).getTime();
  const end_date = GetEndDate();
  const data = await GetDashboardData({ start_date, end_date });
  setData(data || {});
}

export default function Dashboard() {
  const [dashData, setDashData] = useState({});
  const [actionActive, setAction] = useState();

  useEffect(() => {
    getData(setDashData);
  }, []);
  function actionClick(item) {
    setAction(item.id);
  }
  function actionClose() {
    setAction("");
  }
  const dashContent = [
    {
      name: "Total Sale",
      amount: "final_sales",
      icon: <FaChartLine />,
      percent: 75,
      color: "#28a745",
      link: "/",
    },
    {
      name: "Today Sale",
      amount: "total_sale",
      count: "total_orders",
      icon: <FaChartBar />,
      percent: 85,
      color: "#6610f2",
      link: "/sales/history",
    },
    {
      name: "Pending Orders",
      amount: "pending_total",
      count: "pending_orders",
      icon: <MdOutlinePendingActions />,
      percent: 45,
      color: "#ffc107",
      link: "/sales/pending",
    },
    {
      name: "Total Customer",
      amount: "total_user",
      icon: <FaUsers />,
      percent: 72,
      color: "#e83e8c",
      link: "/manage-customer/customer-list",
    },
  ];

  const startRattings = [
    { title: "5 Start", percent: 63, color: "#28a745" },
    { title: "4 Start", percent: 20, color: "#6610f2" },
    { title: "3 Start", percent: 10, color: "#fd7e14" },
    { title: "2 Start", percent: 5, color: "#e83e8c" },
    { title: "1 Start", percent: 2, color: "#dc3545" },
  ];
  const [detailInvoice, setDetailInvoice] = useState();
  async function onViewDetailsClick(item) {
    const productData = await GetIndividualInvoiceWiseReportProductWise(
      `sales_ids=${item.id}`
    );
    setAction();
    // console.log(item);

    setDetailInvoice({
      ...item,
      products: productData,
      total_price: item.grandTotal,
    });
  }

  async function onCheckClick(params) {
    // console.log(11);
    // const data = await GetDeliveryRation("01911824143");
    // console.log(data);
  }
  let orderTotal = 0;
  return (
    <div className="grid grid-cols-4 gap-3 w-[96%] mx-auto py-4">
      {detailInvoice && (
        <InvoiceDetails
          setAction={setAction}
          popUp={detailInvoice}
          setPopUp={setDetailInvoice}
        />
      )}

      {dashContent.map((item, index) => {
        return (
          <Link
            to={item.link}
            key={index}
            className="border border-gray-100 p-4 rounded-md shadow-md flex justify-between items-center"
          >
            <div className="flex flex-col gap-1">
              <p className="text-gray-500">{item.name}</p>
              <p className="font-bold text-teal-600 text-[20px]">
                {dashData[item.amount]
                  ? parseFloat(dashData[item.amount])?.toFixed(2)
                  : "0"}{" "}
                {item.count
                  ? `(${dashData[item.count] ? dashData[item.count] : "0"})`
                  : ""}
              </p>
            </div>
            <p className="text-4xl "> {item.icon}</p>
          </Link>
        );
      })}

      <div className="flex flex-col gap-2 col-span-4 shadow-lg p-4">
        <p className="text-2xl text-center font-bold text-gray-700">
          Order Source View
        </p>
        <div className="flex gap-3">
          <div className="custom-table">
            <table>
              <thead>
                <tr>
                  <th>SL</th>
                  <th>Source</th>
                  <th>Quantity</th>
                </tr>
              </thead>
              <tbody>
                {dashData?.sourceData?.map((item, index) => {
                  orderTotal += parseInt(item.source_total);
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td className="text-left">
                        {OrderMethodObject[item.method_code] || "Not Assigned"}
                      </td>
                      <td>{item.source_total}</td>
                    </tr>
                  );
                })}
                <tr className="font-bold">
                  <td colSpan={2} className="text-right">
                    Total
                  </td>
                  <td>{orderTotal}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="h-48">
            <PieChart pidata={dashData.sourceData} />
          </div>
        </div>
      </div>

      <div className="col-span-3 flex flex-col gap-2">
        <p className="font-bold text-[20px]">Day Wise Sales</p>
        <MyChart />
      </div>

      <div className="col-span-1 flex flex-col gap-2">
        <p className="font-bold text-[20px]">Top Selling Product</p>
        <div className="flex flex-col gap-2">
          {dashData.topSelling?.map((item, index) => {
            return (
              <div
                key={index}
                className="flex gap-1 p-2 shadow-md items-center"
              >
                <div className="w-10 border border-gray-400 rounded-md overflow-auto aspect-square">
                  <ProductImageShow name={item.itemImageName} />
                </div>
                <div className="flex gap-2 items-center flex-1 justify-between">
                  <p className="text-[12px]">{item.product_name}</p>
                  <p className="bg-blue-100 text-blue-500 py-1 px-2 rounded-md font-bold">
                    {item.quantity}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <div className="col-span-3 flex flex-col gap-2">
        <p className="font-bold text-[20px]" onClick={onCheckClick}>
          Latest Orders{" "}
        </p>
        <div className="custom-table">
          <table>
            <thead>
              <tr>
                <th>SL</th>
                <th>Customer Name</th>
                <th>Order Id</th>
                <th>Date</th>
                <th>Total</th>
                <th>District</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {dashData.latestOrders?.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.name}</td>
                    <td>#{item.id}</td>
                    <td>{ServerTimeToFormateTime(item.created_date)}</td>
                    <td>{item.grandTotal}</td>
                    <td>{item.District}</td>
                    <td>
                      <div className="flex relative items-center justify-center">
                        <button onClick={() => actionClick(item)}>
                          <HiDotsHorizontal />
                        </button>
                        {actionActive === item.id ? (
                          <div className="absolute bg-white shadow-md top-3 right-2 z-10 p-3 w-32 flex flex-col">
                            <button
                              className="text-right"
                              onClick={() => actionClose(item)}
                            >
                              <IoClose />
                            </button>
                            <button
                              className=""
                              onClick={() => onViewDetailsClick(item)}
                            >
                              View Details
                            </button>
                            <Link to={`/sales/pending`}>Panding Sales</Link>
                            <Link to={`/sales/history`}>Sales History</Link>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>

      <div className="col-span-1 flex flex-col gap-2">
        <p className="font-bold text-[20px]">Customer Reviews</p>
        <div className="flex flex-col bg-orange-100 p-2 items-center justify-center">
          <div className="flex gap-2 text-orange-400">
            <FaStar />
            <FaStar />
            <FaStar />
            <FaStar />
            <FaStarHalfAlt />
          </div>
          <p className="text-gray-600">(4.5 ratting out of 5)</p>
        </div>
        <div className="flex flex-col gap-2">
          {startRattings.map((item, index) => {
            return (
              <div className="flex gap-2 w-full" key={index}>
                <p>{item.title}</p>
                <div className="flex-1">
                  <Line
                    percent={item.percent}
                    strokeWidth={8}
                    strokeColor={item.color}
                    trailWidth={8}
                  />
                </div>

                <p>{`${item.percent}`.padStart(0, 2)}%</p>
              </div>
            );
          })}
        </div>
      </div>
      <div className="p-6"></div>
    </div>
  );
}

const ProductImageShow = ({ name }) => {
  const [image, setImage] = useState();

  useEffect(() => {
    async function getLink() {
      if (name) {
        let imageInfo = await GetProductImageLink({ image_name: name });
        if (imageInfo.imageExist) {
          setImage(imageInfo.url);
        } else {
          setImage("");
        }
      } else {
        setImage("");
      }
    }
    getLink();
  }, [name]);

  return <> {image ? <img src={image} alt={name} /> : ""}</>;
};
