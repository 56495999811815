import { Route, Routes } from "react-router-dom";
// import PageNotFound from "../../pages/PageNotFound";
import CategoryMain from "./Category/CategoryMain";
import BlogList from "./BlogComps/BlogList";
import AddNewBlog from "./BlogComps/AddNewBlog";
import BlogImages from "./BlogComps/BlogImages";
import BlogMain from "./BlogMain";

export default function BlogRoutes() {
  return (
    <Routes>
      <Route path="/" element={<BlogMain />} />
      <Route path="/blog-list" element={<BlogList />} />
      <Route path="/add-new-blog" element={<AddNewBlog />} />
      <Route path="/blog-categories" element={<CategoryMain />} />
      <Route path="/blog-keywords" element={<>hello</>} />
      <Route path="/blog-images" element={<BlogImages />} />
      {/* <Route path="/*" element={<PageNotFound />} /> */}
    </Routes>
  );
}
