import React, { useEffect, useState } from "react";
import {
  GetBlogPostByQuery,
  GetBlogPosts,
  UpdateBlogPosts,
} from "../../../api/BlogApi";
import plistCss from "./css/productlist.module.css";
import BlogContent from "./models/BlogContent";
import BlogImage from "./models/BlogImage";
import EditBlog from "./models/EditBlog";
// import ToggleSwitch from "../../GlobalElement/ToggleSwitch";
import RelatedProduct from "./models/RelatedProduct";
import ToggleSwitch from "../../smallThings/ToggleSwitch";
import { toast } from "react-toastify";

async function getPLData(setData) {
  const data = await GetBlogPosts();
  setData(data);
}
export default function BlogList() {
  const [tableData, setTableData] = useState([]);
  const [pimagePop, setPimagePop] = useState();
  const [editData, setEditData] = useState();
  const [contentData, setContentData] = useState();
  const [relatedProduct, setRelatedProduct] = useState();

  useEffect(() => {
    if (!pimagePop && !editData) {
      getPLData(setTableData);
    }
  }, [pimagePop, editData]);

  async function onImageClick(item) {
    setPimagePop(item);
  }
  async function onContentUpdate(item) {
    try {
      const data = await GetBlogPostByQuery(`slug=${item.blog_slug}`);
      setContentData(data[0]);
    } catch (error) {
      toast(error.message);
    }
  }

  function onEditClick(item) {
    setEditData(item);
    document.body.style.overflow = "hidden";
  }
  async function onToggleClick(item, mstate) {
    console.log(item, mstate);
    try {
      await UpdateBlogPosts(item.blog_post_id, {
        is_published: !item.is_published,
      });
      getPLData(setTableData);
      toast.success("Blog Status Update Success");
    } catch (error) {
      toast.error(error.message);
    }
  }
  async function onRelatedClick(item) {
    setRelatedProduct(item);
    document.body.style.overflow = "hidden";
  }
  return (
    <div className="flex flex-col gap-4 w-[96%] mx-auto my-6">
      {pimagePop && <BlogImage popUp={pimagePop} setPopUp={setPimagePop} />}
      {contentData && (
        <BlogContent popUp={contentData} setPopUp={setContentData} />
      )}
      {editData && <EditBlog popUp={editData} setPopUp={setEditData} />}
      {relatedProduct && (
        <RelatedProduct popUp={relatedProduct} setPopUp={setRelatedProduct} />
      )}
      <p className="text-center text-2xl font-bold">Blog List</p>
      <div className="custom-table">
        <table>
          <thead>
            <tr>
              <th>SL</th>
              <th>Blog Title</th>
              <th>Product Name bn</th>
              <th>Slug</th>
              <th>Published</th>
            </tr>
          </thead>
          <tbody>
            {tableData.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.blog_title_en}</td>
                    <td>{item.blog_title_bn}</td>
                    <td>{item.blog_slug}</td>
                    <td>
                      <ToggleSwitch
                        toggleOnOff={item.is_published}
                        toggleClick={onToggleClick}
                        item={item}
                      />
                      {/* <ToggleSwitch
                        toggleOnOff={item.is_published}
                        item={item}
                        toggleClick={onToggleClick}
                      /> */}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={12}>
                      <div className="flex gap-4 justify-center items-center">
                        <button
                          className="px-4 py-2 bg-green-500 text-white"
                          onClick={() => onImageClick(item)}
                        >
                          Blog Image
                        </button>
                        <button
                          className="px-4 py-2 bg-orange-500 text-white"
                          onClick={() => onContentUpdate(item)}
                        >
                          Blog Content
                        </button>
                        <button
                          className="px-4 py-2 bg-blue-500 text-white"
                          onClick={() => onRelatedClick(item)}
                        >
                          Related Product
                        </button>
                        <button
                          className="px-4 py-2 bg-teal-500 text-white"
                          onClick={() => onEditClick(item)}
                        >
                          Edit
                        </button>
                      </div>
                    </td>
                  </tr>
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
