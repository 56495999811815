import React from "react";
import { BiPurchaseTag } from "react-icons/bi";
import {
  FaBlog,
  FaChartBar,
  FaHandHoldingUsd,
  FaRegClipboard,
} from "react-icons/fa";
import { GoGear } from "react-icons/go";
import { LuShoppingBag } from "react-icons/lu";
import { MdAccountBalance, MdOutlineDashboard } from "react-icons/md";
import { RiCustomerServiceFill } from "react-icons/ri";
import { NavLink } from "react-router-dom";
import commonCss from "./css/common.module.css";
import { useDispatch, useSelector } from "react-redux";
import { selectMenuShow, setMenuShow } from "./ReduxFiles/Slices/ProductSlice";

const SideNavBar = () => {
  const menuItems = [
    { title: "Dashboard", link: "/", icon: <MdOutlineDashboard /> },
    { title: "Products", link: "/products", icon: <LuShoppingBag /> },
    { title: "Sales", link: "/sales", icon: <FaChartBar /> },
    { title: "Purchase", link: "/purchase", icon: <BiPurchaseTag /> },
    { title: "Report", link: "/reports", icon: <FaRegClipboard /> },
    { title: "Accounts", link: "/accounts", icon: <MdAccountBalance /> },
    { title: "Expense", link: "/expense", icon: <FaHandHoldingUsd /> },
    {
      title: "Manage Customer",
      link: "/manage-customer",
      icon: <RiCustomerServiceFill />,
    },
    { title: "Site Setting", link: "/site-setting", icon: <GoGear /> },
    { title: "Blog", link: "/blog", icon: <FaBlog /> },
  ];
  const showMenu = useSelector(selectMenuShow);
  const dispatch = useDispatch();
  function onMenuClick() {
    dispatch(setMenuShow(false));
  }
  return (
    <div
      className={
        showMenu
          ? `${commonCss.wrapper} ${commonCss.mobile_show}`
          : `${commonCss.wrapper} ${commonCss.mobile_hide}`
      }
    >
      <div className={commonCss.side_inner}>
        {menuItems.map((item, index) => {
          return (
            <div key={index} className={`${commonCss.menu_item} sidemenu`}>
              <NavLink onClick={onMenuClick} to={item.link}>
                {" "}
                {item.icon} {item.title}
              </NavLink>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SideNavBar;
