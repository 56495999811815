import React, { useEffect, useState } from "react";
import { GetEndDate, GetStartDate } from "../MyFunctions/MyFunctions";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import Select from "react-select";
import {
  GetCompleteSalesData,
  GetDeclineSalesData,
  GetHistorySalesData,
  GetReturnSalesData,
} from "../../api/SalesApi";
import {
  fetchInitCustomer,
  fetchSearchedCustomer,
  selectCustomerStatus,
  selectInitCustomer,
} from "../../ReduxFiles/Slices/CustomerSlice";

const SalesSearchComp = ({ passedFrom, setFilteredData, limitNumber }) => {
  const customerStatus = useSelector(selectCustomerStatus);
  const allCustomer = useSelector(selectInitCustomer);
  const dispatch = useDispatch();
  const [searchDate, setSearchDate] = useState();

  function onInputChange(e) {
    let name = e.target.name;
    let value = e.target.value;
    setSearchDate({ ...searchDate, [name]: value });
  }

  function onCustomerChange(data) {
    setSearchDate({ ...searchDate, customer_id: data.id });
  }
  const onInputDataChange = (inputValue, { action }) => {
    if (action === "input-change") {
      if (inputValue.length >= 3) {
        dispatch(fetchSearchedCustomer(inputValue));
      }
    }
  };
  const [, setSearchParams] = useSearchParams();
  async function searchClick() {
    let data = {};
    let myStartDate = "";
    let myEndDate = "";

    if (searchDate?.start_date && searchDate?.end_date) {
      myStartDate = new Date(GetStartDate(searchDate?.start_date)).getTime();
      myEndDate = new Date(GetEndDate(searchDate?.end_date)).getTime();
    }
    setSearchParams({ start_date: myStartDate, end_date: myEndDate });

    if (
      searchDate?.start_date &&
      searchDate?.end_date &&
      searchDate?.customer_id
    ) {
      let myStartDate = new Date(
        GetStartDate(searchDate?.start_date)
      ).getTime();
      let myEndDate = new Date(GetEndDate(searchDate.end_date)).getTime();
      data = {
        start_date: myStartDate,
        end_date: myEndDate,
        customer_id: searchDate.customer_id,
        clicked: 1,
      };
      searchDataFunction(data);
    } else if (searchDate?.start_date && searchDate?.end_date) {
      let myStartDate = new Date(
        GetStartDate(searchDate?.start_date)
      ).getTime();
      let myEndDate = new Date(GetEndDate(searchDate.end_date)).getTime();
      data = {
        start_date: myStartDate,
        end_date: myEndDate,
        clicked: 2,
      };
      searchDataFunction(data);
    } else if (searchDate?.customer_id) {
      data = {
        customer_id: searchDate.customer_id,
        clicked: 3,
      };
      searchDataFunction(data);
    } else if (searchDate?.invoice_no) {
      data = {
        invoice_no: searchDate?.invoice_no,
        clicked: 4,
      };
      searchDataFunction(data);
    } else {
      alert("start date & end date required or Select a Customer to Filter");
    }
  }
  async function resetClick() {
    if (!limitNumber) {
      limitNumber = 10;
    }
    setSearchDate();
    switch (passedFrom) {
      case "complete":
        CompleteData();
        break;
      case "history":
        HistoryData();
        break;
      case "rhistory":
        ReturnHistoryData();
        break;
      case "declined":
        DeclinedData();
        break;
      default:
        break;
    }

    dispatch(fetchInitCustomer(10));
  }
  async function searchDataFunction(passedData) {
    //complete
    switch (passedFrom) {
      case "complete":
        CompleteData(passedData);
        break;
      case "history":
        HistoryData(passedData);
        break;
      case "rhistory":
        ReturnHistoryData(passedData);
        break;
      case "declined":
        DeclinedData(passedData);
        break;
      default:
        break;
    }
  }

  async function HistoryData(passedData) {
    let historyData = await GetHistorySalesData({ ...passedData });
    let sortArr = historyData.sort((a, b) => (a.id > b.id ? -1 : 1));
    setFilteredData(sortArr);
  }
  async function DeclinedData(passedData) {
    let declineData = await GetDeclineSalesData(passedData);
    setFilteredData(declineData);
  }
  async function ReturnHistoryData(passedData) {
    let returnData = await GetReturnSalesData({ ...passedData });
    let tempArr = [];
    returnData.forEach((element) => {
      element = {
        ...element,
        discount: (element.total_orprice * element.discountPercent) / 100,
      };
      tempArr.push(element);
    });
    setFilteredData(tempArr);
  }
  async function CompleteData(passedData) {
    let data = await GetCompleteSalesData({
      limit: limitNumber,
      ...passedData,
    });
    // console.log(data);

    let tempArr = [];
    data?.forEach((element) => {
      let perdiscount =
        (element.discountPercent * parseInt(element.total_orprice)) / 100;
      let discount = element.total_orprice - element.total_price + perdiscount;
      let total =
        parseInt(element.total_orprice) + element.deliveryCharge - discount;
      let due = total - (element.receiveAmount + element.rprice);
      tempArr.push({ ...element, total, due });
    });
    setFilteredData(tempArr);
  }

  useEffect(() => {
    if (customerStatus === "idle") {
      dispatch(fetchInitCustomer(10));
    }
  }, []);

  return (
    <div className="flex gap-2 ">
      <div className="grid grid-cols-4 gap-4 flex-1 justify-between">
        <div className="flex flex-col gap-2">
          <label>Start Date *</label>
          <input
            onChange={onInputChange}
            value={searchDate?.start_date || ""}
            type="date"
            name="start_date"
          />
        </div>
        <div className="flex flex-col gap-2">
          <label>End Date *</label>
          <input
            onChange={onInputChange}
            value={searchDate?.end_date || ""}
            type="date"
            name="end_date"
          />
        </div>
        <div className="flex flex-col gap-2">
          <label>Invoice No *</label>
          <input
            onChange={onInputChange}
            value={searchDate?.invoice_no || ""}
            type="number"
            name="invoice_no"
            placeholder="invoice no"
          />
        </div>
        <div className="flex flex-col gap-2">
          <label>Customer Number*</label>
          <Select
            options={allCustomer}
            getOptionLabel={(option) => `${option.name} - ${option.phone}`}
            getOptionValue={(option) => option.id}
            onChange={onCustomerChange}
            onInputChange={onInputDataChange}
          />
        </div>
      </div>
      <div className="flex self-end gap-3">
        <button
          className="bg-teal-600 text-white px-4 py-2"
          onClick={searchClick}
        >
          Search
        </button>

        <button
          className="bg-red-600 text-white px-4 py-2"
          onClick={resetClick}
        >
          Reset
        </button>
      </div>
    </div>
  );
};

export default SalesSearchComp;
