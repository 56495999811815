import React, { useEffect, useState } from "react";
import {
  DeletePageContent,
  GetPageContent,
  UpdatePageContent,
} from "../../../api/PagesApi";
import { Link, Route, Routes } from "react-router-dom";
import PageContentEdit from "./PageContentEdit";
import ToggleSwitch from "../../smallThings/ToggleSwitch";
import { toast } from "react-toastify";
import pageContentMainCss from "./css/PageContentMain.module.css";
import PopularEdit from "./models/PopularEdit";
import ConfirmAlert from "../../smallThings/ConfirmAlert";

async function getData(setData) {
  const data = await GetPageContent();
  setData(data);
}

const PageContentMain = () => {
  return (
    <div>
      <Routes>
        <Route path="/" element={<MainView />} />
        <Route path="/edit" element={<PageContentEdit />} />
      </Routes>
    </div>
  );
};

export default PageContentMain;

const MainView = () => {
  const [pageData, setPageData] = useState([]);
  async function onToggleClick(item) {
    try {
      await UpdatePageContent(item.content_id, {
        is_visible: !item.is_visible,
      });
      toast.success("Data updated");
      getData(setPageData);
    } catch (error) {
      toast.error(error.message);
    }
  }
  const [editData, setEditData] = useState();
  function onEditClick(item) {
    setEditData(item);
    document.body.style.overflow = "hidden";
  }

  useEffect(() => {
    getData(setPageData);
  }, [editData]);
  const [deleteData, setDeleteData] = useState();
  async function handleDelete(item) {
    setDeleteData(item);
    document.body.style.overflow = "hidden";
  }

  async function yesClick() {
    await DeletePageContent(deleteData.content_id);
    getData(setPageData);
    toast.success("Content Deleted Successfully!");
    setDeleteData();
    document.body.style.overflow = "auto";
  }
  function noClick() {
    setDeleteData();
    document.body.style.overflow = "auto";
  }

  return (
    <div className={pageContentMainCss.salesMain}>
      {deleteData && <ConfirmAlert yesClick={yesClick} noClick={noClick} />}
      <h2>Page Content</h2>
      <div className={pageContentMainCss.innerSale}>
        <div className={pageContentMainCss.tableSection}>
          {editData && <PopularEdit popUp={editData} setPopUp={setEditData} />}
          <table>
            <thead>
              <tr>
                <th>SL</th>
                <th>Page Id</th>
                <th>Page Name</th>
                <th>Content</th>
                <th>Popular Search</th>
                <th>Visible</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {pageData.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.page_id}</td>
                    <td>{item.catName ? item.catName : "Home"}</td>
                    <td>{item.my_content}</td>
                    <td>
                      <div className={pageContentMainCss.popular_edit}>
                        {item.popular_search?.slice(0, 100)}
                        <div className={pageContentMainCss.action_buttons}>
                          <button onClick={() => onEditClick(item)}>
                            Edit
                          </button>
                        </div>
                      </div>
                    </td>
                    <td>
                      <ToggleSwitch
                        toggleOnOff={item.is_visible}
                        toggleClick={onToggleClick}
                        item={item}
                      />
                    </td>
                    <td>
                      <div className={pageContentMainCss.action_buttons}>
                        <Link
                          className={pageContentMainCss.edite_btn}
                          to={`edit?content_id=${item.content_id}`}
                        >
                          Edit
                        </Link>
                        <button
                          onClick={() => handleDelete(item)}
                          className="bg-red-500 px-2 text-white"
                        >
                          Delete
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
