import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import Select from "react-select";
import { GetBlogCategory, UpdateBlogCategory } from "../../../../api/BlogApi";
import addNewCategoryCss from "./addNewCategory.module.css";

async function getCategory(setData) {
  const data = await GetBlogCategory();
  setData(data);
}
export default function UpdateCategory({ popUp, setPopUp }) {
  const [categoryList, setCategoryList] = useState();

  useEffect(() => {
    getCategory(setCategoryList);
  }, []);

  async function onAddCategory(e) {
    e.preventDefault();
    const category_name = e.target.category_name.value;
    const category_slug = e.target.category_slug.value;
    const parent_category = e.target.parent_category.value || 0;
    // console.log({ category_name, category_slug, parent_category });
    // AddCategory
    try {
      await UpdateBlogCategory(popUp.blog_category_id, {
        category_name,
        category_slug,
        parent_category,
      });
      // console.log(popUp);
      toast.success("Category Updated Successfully!");
      setPopUp(false);
    } catch (error) {
      toast.error(error.response.data);
    }
  }

  async function Close() {
    setPopUp(false);
  }

  return (
    <div className={addNewCategoryCss.opupSectionWrapper}>
      <div className={addNewCategoryCss.innerWrapper}>
        <div className={addNewCategoryCss.head}>
          <h3>Update Category</h3>
        </div>
        <form
          className={addNewCategoryCss.formWrapper}
          onSubmit={onAddCategory}
        >
          <div className={addNewCategoryCss.inputWrapper}>
            <label>Category Name</label>
            <input
              type="text"
              name="category_name"
              required
              defaultValue={popUp.category_name}
            />
          </div>
          <div className={addNewCategoryCss.inputWrapper}>
            <label>Category Slug</label>
            <input
              type="text"
              name="category_slug"
              required
              defaultValue={popUp.category_slug}
            />
          </div>
          <div className={addNewCategoryCss.inputWrapper}>
            <label>Parent Category</label>
            <Select
              options={categoryList}
              getOptionLabel={(option) => `${option.category_name}`}
              getOptionValue={(option) => `${option.blog_category_id}`}
              className={addNewCategoryCss.section}
              name="parent_category"
              isClearable
            />
          </div>
          <div className={addNewCategoryCss.inputWrapper}>
            <button>Update Category</button>
            <button type="button" onClick={() => Close()}>
              close
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
