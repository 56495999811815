import JoditEditor from "jodit-react";
import { useRef } from "react";

import { toast } from "react-toastify";
import { UpdatePageContent } from "../../../api/PagesApi";
import { FaTimesCircle } from "react-icons/fa";
export default function UpdatePageContentPop({ popUp, setPopUp }) {
  const config = {
    useSplitMode: false,
    toolbarSticky: true,
    toolbarStickyOffset: 51,
    uploader: {
      insertImageAsBase64URI: true,
    },
    placeholder: "Type Your Content Here...",
    showXPathInStatusbar: false,
    cleanHTML: {
      rules: {
        h1: {
          removeTags: ["span"], // Remove <span> inside <h1>
        },
      },
      cleanOnPaste: true,
    },
    allowInlineStyles: true, // Allow inline styles to transfer them
  };
  const my_content = useRef();
  const popular_search = useRef();
  //   console.log(popUp);
  async function handleUpdate() {
    // console.log(popUp);

    const content = my_content.current.value;
    const cleanData = cleanH1Content(content);
    try {
      await UpdatePageContent(popUp.content_id, {
        my_content: cleanData,
      });
      toast.success("Data updated");
    } catch (error) {
      toast.error(error.message);
    }
  }

  const cleanH1Content = (content) => {
    const cleanedContent = content.replace(
      /<h1(.*?)>(.*?)<\/h1>/g,
      (_, attributes, innerContent) => {
        // Create a temporary container for parsing
        const tempDiv = document.createElement("div");
        tempDiv.innerHTML = innerContent;

        // Extract styles from nested tags
        const nestedStyles = Array.from(tempDiv.querySelectorAll("*"))
          .map((el) => el.getAttribute("style") || "")
          .filter(Boolean);

        // Merge all styles
        const uniqueStyles = {};
        nestedStyles
          .join(";")
          .split(";")
          .forEach((style) => {
            const [key, value] = style.split(":").map((s) => s.trim());
            if (key) uniqueStyles[key] = value;
          });

        // Build the final `style` attribute for <h1>
        const finalStyle = Object.entries(uniqueStyles)
          .map(([key, value]) => {
            // Remove unnecessary quotes around font-family values
            if (key === "font-family") {
              value = value.replace(/["']/g, "");
            }
            return `${key}: ${value}`;
          })
          .join("; ");

        // Extract plain text content
        const textContent = tempDiv.textContent || "";

        // Return cleaned <h1> tag
        return `<h1 ${attributes.trim()} style="${finalStyle}">${textContent}</h1>`;
      }
    );
    return cleanedContent;
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-[100] flex items-center justify-center">
      <div className="bg-white w-[90%] mx-auto h-[90vh] overflow-auto flex flex-col gap-3">
        <div className="flex justify-between px-4 py-2 sticky top-0 bg-teal-600 text-white z-10">
          <p className="font-bold text-2xl">
            Update Page Content ({popUp.catName})
          </p>

          <button
            className="bg-white text-green-500 text-2xl px-3 py-1 font-bold hover:bg-green-500 hover:text-white transition-all duration-200"
            onClick={handleUpdate}
          >
            Update
          </button>
          <button
            className="bg-white text-red-500 text-2xl px-2 hover:bg-red-500 hover:text-white transition-all duration-200"
            onClick={() => {
              setPopUp();
              document.body.style.overflow = "auto";
            }}
          >
            <FaTimesCircle />
          </button>
        </div>
        <div className="px-4">
          <p className="py-2 text-center text-2xl font-bold text-red-600">
            Content
          </p>
          <JoditEditor
            value={popUp.my_content || ""}
            config={config}
            tabIndex={1}
            ref={my_content}
          />
        </div>
        <div className="px-4 pb-6">
          <p className="py-2 text-center text-2xl font-bold text-red-600">
            Popular Search
          </p>
          <JoditEditor
            value={popUp.popular_search || ""}
            config={config}
            tabIndex={1}
            ref={popular_search}
          />
        </div>
      </div>
    </div>
  );
}
