import React, { useEffect, useState } from "react";
import styles from "./css/blogimage.module.css";
import AddBlogImagesPop from "./models/AddBlogImagesPop";
import { GetBodyImages } from "../../../api/BlogApi";
import { API_LINK } from "../../../api/APILink";
import { FaCopy, FaEdit, FaTrashAlt } from "react-icons/fa";
import { toast } from "react-toastify";
async function getImages(setData) {
  const data = await GetBodyImages();
  setData(distributeItems(data));
}

function distributeItems(arr) {
  // Initialize four empty arrays
  const array1 = [];
  const array2 = [];
  const array3 = [];
  const array4 = [];

  arr.forEach((item, index) => {
    const position = index % 4;
    if (position === 0) {
      array1.push(item);
    } else if (position === 1) {
      array2.push(item);
    } else if (position === 2) {
      array3.push(item);
    } else if (position === 3) {
      array4.push(item);
    }
  });

  return [array1, array2, array3, array4];
}

export default function BlogImages() {
  const [bodyImages, setBodyImages] = useState([]);
  const [addImages, setAddImages] = useState(false);
  useEffect(() => {
    !addImages && getImages(setBodyImages);
  }, [addImages]);
  function onCopyClick(item) {
    const link = `${API_LINK}blogimages/bodyimages/${item.image_name}`;
    try {
      window.navigator.clipboard.writeText(link);
      toast.success("Link Copied to Your Clipboard!");
    } catch (error) {
      toast.error(error.message);
    }
  }
  return (
    <div className="flex flex-col gap-4 w-[96%] mx-auto bg-gray-100  min-h-screen">
      {addImages && <AddBlogImagesPop setPopUp={setAddImages} />}
      <div className="flex gap-4 flex-col items-center py-4 bg-white">
        <div className="flex gap-3  items-center w-full">
          <button
            onClick={() => {
              setAddImages(true);
              document.body.style.overflow = "hidden";
            }}
            className="bg-green-600 text-white py-2 px-4"
          >
            Add Blog Images
          </button>
          <input
            type="search"
            className="flex-1 p-2"
            placeholder="search image here"
          />
        </div>
        <div className="flex gap-4 p-2">
          {bodyImages.map((item, index) => {
            return (
              <div
                className="flex-[25%] max-w-[25%] flex flex-col gap-3"
                key={index}
              >
                {item.map((mitem, mindex) => {
                  return (
                    <div
                      key={mindex + "inner"}
                      className="flex flex-col gap-4 p-3 border border-gray-400 rounded-md"
                    >
                      <img
                        src={`${API_LINK}blogimages/bodyimages/${mitem.image_name}`}
                        alt={mitem.image_name}
                      />
                      <div className="flex flex-col gap-3">
                        <p className="font-bold text-gray-600">
                          {mitem.alter_text}
                        </p>
                        <div className="flex items-center justify-between px-4 gap-4">
                          <button
                            className="flex-1 bg-green-500 text-white flex items-center justify-center p-2"
                            onClick={() => onCopyClick(mitem)}
                          >
                            <FaCopy />
                          </button>
                          <button className="flex-1 bg-orange-500 text-white flex items-center justify-center p-2">
                            <FaEdit />
                          </button>
                          <button className="flex-1 bg-red-500 text-white flex items-center justify-center p-2">
                            <FaTrashAlt />
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
      <div className="p-6"></div>
    </div>
  );
}
