import React, { useState } from "react";
import modelsCss from "./models.module.css";
import { API_LINK } from "../../../../api/APILink";
import toast from "react-hot-toast";
import { UploadBlogImage } from "../../../../api/BlogApi";
export default function BlogImage({ popUp, setPopUp }) {
  const [fakeUrl, setFakeUrl] = useState("");
  function onImageChange(e) {
    const image = e.target.files[0];
    if (image) {
      setFakeUrl(genarateFakeUrl(image));
    }
  }
  async function onImageUoload(e) {
    e.preventDefault();
    const image = e.target.product_image.files[0];
    // const image_alt_text = e.target.image_alt_text.value;
    // console.log(image, image_alt_text, popUp.product_image_id);
    // console.log(popUp.product_image_id);
    try {
      await UploadBlogImage(image, popUp.blog_post_id, popUp.blog_image_name);
      toast.success("Image Upload Success");
      setPopUp();
    } catch (error) {
      toast.success(error.message);
    }
  }

  function genarateFakeUrl(image) {
    let myurl = URL.createObjectURL(image);
    return myurl;
  }
  return (
    <div className="bg-black bg-opacity-50 fixed inset-0 z-[101] flex items-center justify-center">
      <div className="bg-white w-1/2 rounded-lg flex flex-col gap-3">
        <div className="bg-blue-500 text-white p-2 relative">
          <p>Upload Blog Image</p>
          <p>Blog Title : {popUp.blog_title_en}</p>
          <button
            onClick={() => {
              setPopUp();
            }}
            className="absolute right-4 top-3 bg-red-500 p-2"
          >
            Close
          </button>
        </div>
        <form className={modelsCss.my_form} onSubmit={onImageUoload}>
          <input
            name="product_image"
            type="file"
            required
            onChange={onImageChange}
            multiple={false}
            accept="image/webp"
          />
          {/* <input
            name="image_alt_text"
            type="text"
            required
            placeholder="alt text"
          /> */}
          <button>Upload</button>
        </form>
        <div className={modelsCss.image_upload}>
          <div className={modelsCss.img_holder}>
            <img
              src={`${API_LINK}blogimages/blogmainimages/${popUp.blog_image_name}`}
              alt={popUp.product_image_name || "no data found"}
            />
          </div>
          <div className={modelsCss.img_holder}>
            {fakeUrl && <img src={fakeUrl} alt={"no data found"} />}
          </div>
        </div>
      </div>
    </div>
  );
}
