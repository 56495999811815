import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import Select from "react-select";
import {
  AddBlogRelatedProduct,
  GetBlogRelatedProduct,
} from "../../../../api/BlogApi";
import { GetAllProducts } from "../../../../api/ProductListApi";
import modelsCss from "./models.module.css";
async function getProducts(blog_id, setProducts, setTableData) {
  // const products = await GetAllProducts();
  // const relatedProduct = await GetBlogRelatedProduct(`blog_id=${blog_id}`);
  // console.log(relatedProduct);
  // setTableData(relatedProduct);
  // setProducts(products);
}
export default function RelatedProduct({ popUp, setPopUp }) {
  const [addData, setAddData] = useState({});
  const [products, setProducts] = useState([]);
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    getProducts(popUp.blog_post_id, setProducts, setTableData);
  }, [popUp]);
  async function onAddProduct(e) {
    e.preventDefault();
    const product_id = e.target.product_id.value;
    // console.log(popUp, product_id);
    try {
      await AddBlogRelatedProduct({ product_id, blog_id: popUp.blog_post_id });
      toast.success("Related Product Added!");
      e.target.reset();
    } catch (error) {
      toast(error.message, { icon: "❌" });
    }
  }

  return (
    <div className="bg-black bg-opacity-50 fixed inset-0 z-[101] flex items-center justify-center">
      <div className="bg-white w-1/2 rounded-lg flex flex-col gap-3">
        <div className="bg-blue-500 text-white p-2 relative">
          <h3>Update Blog Related Product</h3>
          <p>Product Name : {popUp.blog_title_en}</p>
          <button
            onClick={() => {
              setPopUp();
              document.body.style.overflow = "auto";
            }}
            className="absolute right-4 top-3 bg-red-500 p-2"
          >
            Close
          </button>
        </div>
        <form className={modelsCss.formWrapper} onSubmit={onAddProduct}>
          <div className={modelsCss.inputWrapper}>
            <label>Product</label>
            <Select
              options={products}
              getOptionLabel={(option) => `${option.product_name_en}`}
              getOptionValue={(option) => `${option.product_id}`}
              isClearable
              name="product_id"
            />
          </div>
          <div className={modelsCss.inputWrapper}>
            <button>Add Product</button>
          </div>
        </form>
        <div className="custom-table">
          <table>
            <thead>
              <tr>
                <th>SL</th>
                <th>Product Name</th>
                <th>Category</th>
              </tr>
            </thead>
            <tbody></tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
