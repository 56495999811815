import addnewCss from "./addnew.module.css";

import Select from "react-select";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectAttributeNameList } from "../../../ReduxFiles/Slices/MAU_Slice";
import {
  fetchCategory,
  selectCategoryList,
} from "../../../ReduxFiles/Slices/CategorySlice";
import { SaveCategory } from "../../../api/CategoryApi";
import { toast } from "react-toastify";

const AddNewCategory = ({ setPopup }) => {
  const allCategory = useSelector(selectCategoryList);
  const attributeArray = useSelector(selectAttributeNameList);
  const dispatch = useDispatch();
  function CapitalizeLatter(myString) {
    let firstChar = myString.substring(0, 1);
    firstChar = firstChar.toUpperCase();
    let tail = myString.substring(1).toLowerCase();
    myString = firstChar + tail;
    return myString;
  }
  const [fakeUrlImage, setFakeUrlImage] = useState({});
  function onImageChange(e) {
    let name = e.target.name;
    if (e.target.files[0]) {
      setFakeUrlImage({
        ...fakeUrlImage,
        [name]: genarateFakeUrl(e.target.files[0]),
      });
    } else {
      setFakeUrlImage({ ...fakeUrlImage, [name]: "" });
    }
  }

  function genarateFakeUrl(image) {
    let myurl = URL.createObjectURL(image);
    return myurl;
  }
  async function onDataSubmit(e) {
    e.preventDefault();
    const form = e.target;

    const catImage = form.catImage.files;
    const catBanner = form.catBanner.files;
    const catIcon = form.catIcon.files;

    const catName = form.catName.value.trim();
    const catDescriptioon = form.catDescriptioon.value.trim();
    const catSlug = form.catSlug.value.trim();
    const code = form.code.value;
    const selectedItem = form.selectedItem.value;
    const SelectedAttribute = form.SelectedAttribute.value;
    const finalData = {
      catName: CapitalizeLatter(catName),
      catDescriptioon,
      catSlug: catSlug.replaceAll(" ", "_"),
      code,
      selectedItem: selectedItem || 0,
      SelectedAttribute,
      created_date: new Date().getTime(),
    };
    try {
      await SaveCategory(finalData, catImage[0], catIcon[0], catBanner[0]);
      dispatch(fetchCategory());
      toast.success("Category Saved!");
      setPopup();
      document.body.style.overflow = "auto";
    } catch (error) {
      toast.error(error.message);
    }
  }

  return (
    <div className={addnewCss.wrapper}>
      <div className={addnewCss.holder}>
        <h3>Add New Category</h3>
        <form className={addnewCss.my_form} onSubmit={onDataSubmit}>
          <div className={addnewCss.input_holder}>
            <label>Category Name*</label>
            <input name="catName" type="text" required />
          </div>
          <div className={addnewCss.input_holder}>
            <label>Category Description*</label>
            <input name="catDescriptioon" type="text" required />
          </div>
          <div className={addnewCss.input_holder}>
            <label>Category Slug*</label>
            <input name="catSlug" type="text" required />
          </div>
          <div className={addnewCss.input_holder}>
            <label>Category Code*</label>
            <input name="code" type="text" required />
          </div>
          <div className={addnewCss.input_holder}>
            <label>Select Item Category*</label>
            <Select
              name="selectedItem"
              options={allCategory}
              getOptionLabel={(option) => `${option.catName}`}
              getOptionValue={(option) => `${option.id}`}
              className={addnewCss.my_select}
            />
          </div>
          <div className={addnewCss.input_holder}>
            <label>Select Attribute*</label>
            <Select
              name="SelectedAttribute"
              options={attributeArray}
              getOptionLabel={(option) => `${option.att_name}`}
              getOptionValue={(option) => `${option.id}`}
              className={addnewCss.my_select}
            />
          </div>
          <div className={addnewCss.input_holder}>
            <label>Category Image*</label>
            <div className={addnewCss.image_wrapper}>
              <input
                name="catImage"
                type="file"
                required
                onChange={onImageChange}
              />
              <div className={addnewCss.show_image_sqr}>
                <img src={fakeUrlImage.catImage} />
              </div>
            </div>
          </div>
          <div className={addnewCss.input_holder}>
            <label>Catagory Icon*</label>
            <div className={addnewCss.image_wrapper}>
              <input
                name="catIcon"
                type="file"
                required
                onChange={onImageChange}
              />
              <div className={addnewCss.show_image_sqr}>
                <img src={fakeUrlImage.catIcon} />
              </div>
            </div>
          </div>
          <div className={addnewCss.input_holder}>
            <label>Category Banner*</label>
            <div className={addnewCss.image_wrapper}>
              <input
                name="catBanner"
                type="file"
                required
                onChange={onImageChange}
              />
              <div className={addnewCss.show_image}>
                <img src={fakeUrlImage.catBanner} />
              </div>
            </div>
          </div>
          <div className={addnewCss.btn_group}>
            <button
              type="button"
              onClick={() => {
                setPopup(false);
                document.body.style.overflow = "auto";
              }}
            >
              Close
            </button>
            <button type="submit">Submit</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddNewCategory;
