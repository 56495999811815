import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import {
  DeleteGalleryImage,
  DeleteGalleryItem,
  GetAllGalleryImage,
  GetFilterProducts,
  GetProductImageLink,
} from "../../../../api/ProductListApi";
import UploadGalleryImage from "./editpop/UploadGalleryImage";
import UploadProductImage from "./editpop/UploadProductImage";
import ImageUploaderCss from "../EditItem/css/imageUploader.module.css";

async function getGalleryImages(id, setData) {
  let data = await GetAllGalleryImage(`product_id = ${id}`);
  setData(data);
}
async function getEditData(id, setData) {
  let myData = await GetFilterProducts(`pl.id = ${id}`);
  setData(myData[0]);
}

const ImageUploader = ({ item, setItem }) => {
  const [galleryData, setGalleryData] = useState([]);
  const [deleteItem, setDeleteItem] = useState();
  const [popUpData, setPopUpData] = useState();
  const [popUpDataProduct, setPopUpDataProduct] = useState();

  useEffect(() => {
    if (item.id) {
      getEditData(item.id, setItem);
    }
  }, [popUpDataProduct]);

  useEffect(() => {
    if (item.id) {
      getGalleryImages(item.id, setGalleryData);
    }
  }, [item.id, deleteItem]);
  //{item.itemImageName}
  return (
    <div className={ImageUploaderCss.wrapper}>
      <div className={ImageUploaderCss.holder}>
        {deleteItem && (
          <ConfirmAlert deleteItem={deleteItem} setDeleteItem={setDeleteItem} />
        )}
        {popUpData && (
          <UploadGalleryImage popUp={popUpData} setPopUp={setPopUpData} />
        )}
        {popUpDataProduct && (
          <UploadProductImage
            popUp={popUpDataProduct}
            setPopUp={setPopUpDataProduct}
          />
        )}
        <div className={ImageUploaderCss.inner}>
          <h2>Upload Product Image </h2>
          <button
            onClick={() => {
              setPopUpDataProduct(item);
              console.log(item);
            }}
          >
            Upload
          </button>
          <div className={ImageUploaderCss.images}>
            <ProductImageShow name={item.itemImageName} />
          </div>
        </div>
        <div className={ImageUploaderCss.inner}>
          <h2>Upload Gallery Image</h2>
          <button
            onClick={() => {
              setPopUpData(item);
            }}
          >
            Upload
          </button>
          <div className={ImageUploaderCss.images}>
            {galleryData.map((gitem) => {
              return (
                <span className={ImageUploaderCss.holder}>
                  <FontAwesomeIcon
                    icon={faTimesCircle}
                    onClick={() => setDeleteItem(gitem)}
                  />
                  <ProductImageShow name={gitem.image_name} />
                </span>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageUploader;

const ProductImageShow = ({ name }) => {
  const [image, setImage] = useState();

  async function getLink() {
    // console.log(name);
    if (name) {
      let imageInfo = await GetProductImageLink({ image_name: name });
      // console.log(imageInfo);
      if (imageInfo.imageExist) {
        setImage(imageInfo.url);
      } else {
        setImage("");
      }
    } else {
      setImage("");
    }
  }
  useEffect(() => {
    getLink();
  }, [name]);
  return <> {image ? <img src={image} alt={name} /> : ""}</>;
};

const ConfirmAlert = (props) => {
  const { deleteItem, setDeleteItem } = props;
  async function yesClick() {
    await DeleteGalleryItem(deleteItem.id);
    await DeleteGalleryImage({ image_name: deleteItem.image_name });
    setDeleteItem();
  }
  function noClick() {
    setDeleteItem();
  }

  return (
    <>
      <div className="conAlert">
        <h2 className="caption">Do you Want To Delete This?</h2>
        <div className="buttonsec">
          <input
            onClick={yesClick}
            className="yes"
            type={"submit"}
            value={"Yes"}
          />
          <input
            onClick={noClick}
            className="no"
            type={"submit"}
            value={"No"}
          />
        </div>
      </div>
    </>
  );
};
